<template>
  <div class="d-flex flex-column">
    <v-row class="mb-1">
      <v-col cols="12" sm="6" md="6">
        <router-link
          :to="`/session/${this.sessionId}/sales/${this.worksessionPosId}`"
        >
          <b-button class="mr-1" variant="outline-secondary" size="sm">
            <b-icon icon="arrow-left"></b-icon> volver
          </b-button>
        </router-link>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="text-right">
        <b-button
          class="ml-1"
          variant="primary"
          size="sm"
          @click="onClickEndVisit()"
        >
          End Visit <b-icon icon="arrow-right"></b-icon>
        </b-button>
      </v-col>
    </v-row>
    <div class="border d-flex justify-content-center align-center mt-2 mb-2">
      <template v-if="posSession">
        <span class="trencadis-title my-3"
          >[{{ posSession.po.code || posSession.po.id }}]
          {{ posSession.po.name }}</span
        >
        <sessionTagLabels
          :postaglabels="posSession.po.postaglabels"
        ></sessionTagLabels>
      </template>
    </div>

    <div class="d-flex flex-column mt-2" v-if="posSession && salesData">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-content-start align-center flex-wrap"
        >
          <b-button variant="outline-secondary" class="mr-2 mb-2">
            <v-badge color="green" inline dot>
              Brand A
            </v-badge>
          </b-button>
          <template v-for="i in 5">
            <b-button
              :key="`navbtn_${i}`"
              variant="outline-secondary"
              class="mr-2 mb-2"
            >
              <v-badge color="pink" inline dot> Brand {{ i }} </v-badge>
            </b-button>
          </template>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-card elevation="5" outlined>
                <v-card-title
                  class="d-flex justify-content-between align-center"
                >
                  <span class="h5 mb-0">Punctuation</span>
                  <span>62 <span class="additionalgreenlabel">(+2)</span></span>
                </v-card-title>
                <v-divider class="mx-4 my-0"></v-divider>
                <v-card-text class="text--primary">
                  <table
                    style="width: 100%"
                    border="1"
                    class="text-center endvisit-table"
                  >
                    <tr>
                      <th class="text-left">Action</th>
                      <th>Type</th>
                      <th>Points</th>
                    </tr>
                    <template v-for="i in 5">
                      <tr :key="`tr_${i}`">
                        <td class="text-left">Action - {{ i }}</td>
                        <td>Type - {{ i }}</td>
                        <td>+{{ i * 10 }}</td>
                      </tr>
                    </template>
                    <tr :key="`tr_6`" class="text-center negative_row">
                      <td class="text-left">Action - 6</td>
                      <td>Type - 6</td>
                      <td>+6</td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card elevation="5" outlined>
                <v-card-title
                  class="d-flex justify-content-between align-center"
                >
                  <span class="h5 mb-0">Sales</span>
                  <span>OBJ: 60 €</span>
                </v-card-title>
                <v-divider class="mx-4 my-0"></v-divider>
                <v-card-text class="text--primary">
                  <table
                    style="width: 100%"
                    border="1"
                    class="text-center endvisit-sales-table"
                  >
                    <tr>
                      <th class="text-left">Product</th>
                      <th>Current</th>
                      <th>vs Last</th>
                    </tr>
                    <template v-for="i in 5">
                      <tr :key="`tr_${i}`">
                        <td class="text-left">Product - {{ i }}</td>
                        <td>{{ i }}€</td>
                        <td>+{{ i * 10 }}€</td>
                      </tr>
                    </template>
                    <tr :key="`tr_6`" class="grey_row">
                      <td class="text-left">Total</td>
                      <td>50€</td>
                      <td>+5€</td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col
          cols="12"
          md="12"
          class="d-flex justify-content-start align-center flex-wrap editpos_group_btn"
        >
          <b-button variant="danger" class="mr-2 mb-2">
            Label 1
          </b-button>
          <b-button variant="primary" class="mr-2 mb-2">
            Label 2
          </b-button>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="6">
          <v-card elevation="5" outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="6">Brand Messaging</v-col>
                <v-col cols="12" md="6" class="text-right">
                  <b-button class="mx-1" variant="primary" size="sm">
                    <b-icon icon="plus"></b-icon> Add
                  </b-button>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <div class="scroll_contents">
                <p class="unread">
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="5" outlined>
            <v-card-title>
              <v-row>
                <v-col cols="12" md="6">Brand Incidences</v-col>
                <v-col cols="12" md="6" class="text-right">
                  <b-button class="mx-1" variant="primary" size="sm">
                    <b-icon icon="plus"></b-icon> Add
                  </b-button>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <div class="scroll_contents">
                <p class="unread">
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
                <p>
                  25/09/22 - Admin or SPV Username: The Incidence is solved.
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <b-alert v-if="!posSession || !salesData" show variant="info">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import sessionTagLabels from "@/components/sessionTagLabels";

export default {
  name: "worksessionPos",
  components: {
    sessionTagLabels,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {},
  data: function() {
    return {
      sessionId: null,
      worksessionPosId: null,
      posSession: null,
      worksessionPosBrands: [],
      salesData: null,
      tab: 1,
    };
  },
  methods: {
    moment,
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    onClickTab(tabValue) {
      this.tab = tabValue;
    },
    onClickEndVisit() {},
    async init() {
      const { id, worksessionPosId } = this.$route.params;
      this.sessionId = id;
      this.worksessionPosId = worksessionPosId;
      const { data, reasonTypes, worksessionPosBrands } = await ApiService.get(
        `worksession/pos/${this.worksessionPosId}`
      );
      this.posSession = data;
      this.worksessionPosBrands = worksessionPosBrands;
      this.salesData = [];

      this.tab = 0;
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style>
.filter-button-group {
  border: 1px solid #ced4da !important;
}
.filter-button-group.btnred {
  color: #cd5c5c;
}
.filter-button-group.btnyellow {
  color: #ffa500;
}
.filter-button-group.btnblue {
  color: #0000ff;
}
.filter-button-group.btngreen {
  color: #228b22;
}
.titlebar {
  border: 1px dashed #aaa;
}
.titlebar span.title {
  background: #666;
  padding: 0 10px;
  color: #fff;
  font-weight: 300 !important;
}
.text-bold {
  font-weight: 800;
}
.unitsaleswrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.unitsaleswrapper .units_item {
  display: flex;
  justify-content: center;
}
.unitsaleswrapper .units_item .value_group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.value_cell {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #999;
  border-right: 0;
  background: #e7e7e7;
}
.value_cell.borderR {
  border-right: 1px solid #999;
}
.value_cell.cellgrey {
  background: #c1c1c1;
}
.value_cell.cellpink {
  background: #ffd7d7;
}
.value_cell.cellwhite {
  background: #fff;
}
.value_detail {
  color: #777;
  font-size: 12px;
  text-align: center;
}
.contentswrapper .content_title {
  margin: 0;
  font-weight: 800;
}
.priority_detail {
  color: #777;
  font-size: 12px;
  text-align: center;
}
.priority_cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #ffa500;
  border-radius: 10px;
  font-size: 2em;
  font-weight: 800;
}
.input_group .input_field {
  width: 50px;
  height: 50px;
  padding: 2px;
  text-align: center;
  margin: 0 3px;
  border: 1px solid #999;
}
.input_group .input_detail {
  color: #777;
  font-size: 12px;
  text-align: center;
}
.flagwrapper {
  display: flex;
  align-items: center;
}
.imagewrapper img {
  max-width: 100px;
  width: 50px;
  height: 50px;
}
.endvisit-table th,
.endvisit-sales-table th {
  background: #203864;
  color: #fff;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
}
.endvisit-table td {
  padding: 10px;
  white-space: pre-wrap;
  overflow: hidden;
  background: #d5e3cf;
}
.endvisit-table tr:nth-child(odd) td {
  background: #ebf1e9;
}
.endvisit-table tr.negative_row td {
  background: #f8d7cd;
}
.endvisit-sales-table td {
  padding: 10px;
  white-space: pre-wrap;
  overflow: hidden;
  background: #cfd5ea;
}
.endvisit-sales-table tr:nth-child(odd) td {
  background: #e9ebf5;
}
.endvisit-sales-table tr.grey_row td {
  background: #a6a6a6;
}
.scroll_contents p {
  color: #aaa;
}
.scroll_contents .unread {
  color: #000;
}
.editpos_group_btn .btn {
  color: #fff !important;
}
</style>
